<template>
  <div id="app">
    <InfoBanner />
    <NavigationBar />
    <Footer />
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import InfoBanner from "@/components/InfoBanner.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  
  components: {
    NavigationBar,
    InfoBanner,
    Footer
  },

  mounted() {
    this.$store.commit('setSession');
  }
};
</script>

<style lang="scss"></style>
